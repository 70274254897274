import React, { useState } from 'react'

import { Row, Col } from 'antd'
import moment from 'moment'
import { PropTypes } from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import { useHistory, useParams } from 'react-router'
import { Field, FieldArray, Form, reduxForm, reset } from 'redux-form'

import ReturnsAndExchangesModalProducts from './ReturnsAndExchangesModalProducts'
import { EditOrderTitle } from './Styles'
import { StartReturnExchange } from '../../../../infra/requests/OrdersB2CRequests'
import FormValidator from '../../../../infra/services/validations/FormValidator'
import AlertService from '../../../../shared/components/alert/AlertService'
import DateInput from '../../../../shared/components/inputs/DateInput'
import SelectInput from '../../../../shared/components/inputs/SelectInput'
import TextInput from '../../../../shared/components/inputs/TextInput'
import BaseModal from '../../../../shared/components/modal/BaseModal'
import { Margin } from '../../../../shared/styles/BasicStyles'
import ValidateReturns from '../helpers/ValidateReturns'

const FormRules = FormValidator.make({
  date: 'required',
  hour: 'required'
})

const ReturnsAndExchangesModal = ({ handleClose, open, onOk, motives, orderProducts, handleSubmit, dispatch, translate }) => {
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState([])
  const { id } = useParams()
  const history = useHistory()

  const transportationCostOptions = [
    { value: 'included', label: translate('INCLUDED') },
    { value: 'notIncluded', label: translate('NOT_INCLUDED') }
  ]

  const onSubmit = async (values) => {
    setLoading(true)

    try {
      if (values.hour.indexOf(':') >= 0) {
        const timeOfTheDay = values.hour.split(':')
        const requestBody = {
          pickupDate: moment(values.date).hour(timeOfTheDay[0]).minute(timeOfTheDay[1]),
          freeShipping: values.transportationCost === 'included',
          items: values.products?.map((x) => ({
            isReturn: x.type == 0 ? true : x.type == 1 ? false : undefined,
            itemId: x.orderItemId,
            quantity: x.quantity,
            comment: x.comments,
            motiveId: x.motive,
            motiveStr: motives.find((motive) => motive.ID == x.motive)?.Name
          }))
        }

        const errors = ValidateReturns(requestBody)
        if (errors.length > 0) {
          setLoading(false)
          return AlertService.showTranslatedValidations(translate('MISSING_INFORMATION'), errors, translate)
        }
        const { success, data } = await StartReturnExchange(id, requestBody)
        if (success) {
          AlertService.success(translate('SUCCESS'), translate('SUCCESS_RETURN_MESSAGE'))
          onOk()
          dispatch(reset('returns-and-exchange-form'))
          history.push(`/returns/b2c/${data}`)
        }
      } else {
        AlertService.error(translate('ERROR'), translate('INVALID_HOUR'))
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <BaseModal
      okText={<Translate id='SAVE' />}
      onOk={handleSubmit(onSubmit)}
      okButtonProps={{ disabled: loading, loading }}
      onClose={() => handleClose(false)}
      title={<Translate id='RETURN_AND_EXCHANGES_ITEMS' />}
      open={open}
    >
      {open && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <EditOrderTitle><Translate id='SELECT_PICKUP_DATE_AND_TIME' /></EditOrderTitle>
            </Col>
            <Col xs={24} md={4}>
              <Field
                component={SelectInput}
                data={transportationCostOptions}
                placeholder={<Translate id='SELECT_TYPE' />}
                dataKey='value'
                dataLabel='label'
                name='transportationCost'
                label={<Translate id='TRANSPORTATION_COST' />}
              />
            </Col>
            <Col xs={24} md={8}>
              <Field
                component={DateInput}
                name='date'
                label={<Translate id='DATE' />}
              />
            </Col>
            <Col xs={24} md={4}>
              <Field
                component={TextInput}
                name='hour'
                label={<Translate id='HOUR' />}
                placeholder='12:00'
              />
            </Col>
          </Row>
          <Margin size={20} />
          <FieldArray
            component={ReturnsAndExchangesModalProducts}
            name='products'
            selected={selected}
            setSelected={setSelected}
            orderProducts={orderProducts}
            motives={motives}
            loading={loading}
            dispatch={dispatch}
          />
        </Form>
      )}
    </BaseModal>

  )
}

ReturnsAndExchangesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  motives: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  orderProducts: PropTypes.array
}

ReturnsAndExchangesModal.defaultProps = {
  orderProducts: []
}

const ReturnsAndExchangesModalPage = reduxForm({
  form: 'returns-and-exchange-form',
  validate: FormRules
})(ReturnsAndExchangesModal)

export default withLocalize(ReturnsAndExchangesModalPage)
